<template>
  <div>
    <topic-comment
      v-for="(id,index) in comments"
      :key="id"
      :commentId="id"
      :dense="dense"
      :topicId="topicId"
      :index="index"
      :outlined="true"
    />
  </div>
</template>

<script>
export default {
  components: {
    TopicComment: () => import('./topic-comment')
  },
  props: {
    comments: {
      type: Array,
      default: () => []
    },
    dense: Boolean,
    topicId: Number,
    orderUp: Boolean
  }
}
</script>
